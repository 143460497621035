// Color system
// stylelint-disable

$Site-bg-color: #f1f3f6;

$white: #fff !default;
$gray-base: #181b1e !default;
$gray-100: #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-150: #e8ebee !default; // lighten($gray-base, 80%);
$gray-200: #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300: #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400: #acb4bc !default; // lighten($gray-base, 60%);
$gray-500: #8f9ba6 !default; // lighten($gray-base, 50%);
$gray-600: #73818f !default; // lighten($gray-base, 40%);
$gray-700: #5c6873 !default; // lighten($gray-base, 30%);
// $red-800: #ff0000 !default; // lighten($gray-base, 10%);
$gray-800: #2f353a !default; // lighten($gray-base, 10%);
$gray-900: #23282c !default; // lighten($gray-base, 5%);
$black: #000 !default;
$gray-1000: #e5e5e5;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue: #20a8d8 !default;
// $blueDark: #031475 !default;
$blueDark: #3065b5 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ee0505 !default;
// $red: #f86c6b !default;
// $orange: #f8cb00 !default;
$orange: #e97a24 !default;
$yellow: #ffc107 !default;
$green: #4dbd74 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$light-blue: #63c2de !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "light-blue": $light-blue,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800
  ),
  $colors
);

$primary: $blueDark !default;
// $primary: $blue !default;
$secondary: $gray-300 !default;
$success: $green !default;
$info: $light-blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
  ),
  $theme-colors
);

// Brand Colors

$facebook: #3b5998 !default;
$twitter: #00aced !default;
$linkedin: #4875b4 !default;
$google-plus: #d34836 !default;
$flickr: #ff0084 !default;
$tumblr: #32506d !default;
$xing: #026466 !default;
$github: #4183c4 !default;
$html5: #e34f26 !default;
$openid: #f78c40 !default;
$stack-overflow: #fe7a15 !default;
$youtube: #b00 !default;
$css3: #0170ba !default;
$dribbble: #ea4c89 !default;
$google-plus: #bb4b39 !default;
$instagram: #517fa4 !default;
$pinterest: #cb2027 !default;
$vk: #45668e !default;
$yahoo: #400191 !default;
$behance: #1769ff !default;
$skype: #1769ff !default;
$dropbox: #007ee5 !default;
$reddit: #ff4500 !default;
$spotify: #7ab800 !default;
$vine: #00bf8f !default;
$foursquare: #1073af !default;
$vimeo: #aad450 !default;

$brands-colors: () !default;
$brands-colors: map-merge(
  (
    "facebook": $facebook,
    "twitter": $twitter,
    "linkedin": $linkedin,
    "google-plus": $google-plus,
    "flickr": $flickr,
    "tumblr": $tumblr,
    "xing": $xing,
    "github": $github,
    "html5": $html5,
    "openid": $openid,
    "stack-overflow": $stack-overflow,
    "youtube": $youtube,
    "css3": $css3,
    "dribbble": $dribbble,
    "instagram": $instagram,
    "pinterest": $pinterest,
    "vk": $vk,
    "yahoo": $yahoo,
    "behance": $behance,
    "dropbox": $dropbox,
    "reddit": $reddit,
    "spotify": $spotify,
    "vine": $vine,
    "foursquare": $foursquare,
    "vimeo": $vimeo
  ),
  $brands-colors
);

// ***********************************************
// node_modules\@coreui\coreui\scss\_variables.scss

// Import CoreUI default colors
// @import "variables/colors";

// Customized Bootstrap variables
// @import "variables/bootstrap/variables";

// Import Bootstrap variables after customization for use below
// @import "bootstrap/functions"; // from bootstrap node_modules
@import "../../node_modules/@coreui/coreui/scss/functions";
// @import "bootstrap/variables";
// from bootstrap node_modules
@import "../../node_modules/@coreui/coreui/scss/bootstrap";
// CoreUI Variables
// stylelint-disable
$enable-sidebar-nav-rounded: false !default;
$layout-transition-speed: 0.25s !default;

// Navbar

$navbar-height: 55px !default;
$navbar-bg: $Site-bg-color;
// $navbar-bg: #fff !default;
$navbar-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color
  )
) !default;
$navbar-brand-width: 155px !default;
$navbar-brand-bg: transparent !default;
$navbar-brand-border: 0 !default;

$navbar-brand-minimized-width: 50px !default;
$navbar-brand-minimized-bg: $navbar-brand-bg !default;
$navbar-brand-minimized-border: $navbar-brand-border !default;

$navbar-color: $gray-900 !default;
$navbar-hover-color: $gray-800 !default;
$navbar-active-color: $gray-800 !default;
$navbar-disabled-color: $gray-300 !default;

$navbar-toggler-icon: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$navbar-toggler-icon-hover: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

// Sidebar

$sidebar-width: 200px !default;
$sidebar-padding: 0 !default;
$sidebar-minimized-width: 50px !default;
$sidebar-minimized-height: $sidebar-minimized-width !default;
$sidebar-compact-width: 150px !default;
$sidebar-compact-height: $sidebar-compact-width !default;
// menu settings
// $sidebar-color: #fff !default;
$sidebar-bg: $gray-200;
$sidebar-color: $gray-300;
// $sidebar-color: $gray-900 !default;
// $sidebar-bg: $Site-bg-color;
$sidebar-borders: none !default;
$mobile-sidebar-width: 220px !default;

// Sidebar Header

$sidebar-header-height: auto !default;
// $sidebar-header-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-header-bg: $gray-900;
$sidebar-header-padding-y: 0.75rem !default;
$sidebar-header-padding-x: 1rem !default;

// Sidebar Form

$sidebar-form-border: 0 !default;
$sidebar-form-bg: darken($sidebar-bg, 10%) !default;
$sidebar-form-color: #fff !default;
$sidebar-form-placeholder-color: rgba(255, 255, 255, 0.7) !default;

// Sidebar Navigation

// $sidebar-nav-color: #fff !default;
// $sidebar-nav-color: $gray-900;
$sidebar-nav-color: $blueDark;
$sidebar-nav-title-padding-y: 0.75rem !default;
$sidebar-nav-title-padding-x: 1rem !default;
$sidebar-nav-title-color: $gray-200 !default;
$sidebar-nav-link-padding-y: 0.75rem !default;
$sidebar-nav-link-padding-x: 1rem !default;
// $sidebar-nav-link-color: #fff !default;
// $sidebar-nav-link-color: $gray-900;
$sidebar-nav-link-color: $gray-900;
$sidebar-nav-link-bg: transparent !default;
$sidebar-nav-link-icon-color: $gray-900;
$sidebar-nav-link-borders: 0 !default;

// $sidebar-nav-link-hover-color: #fff !default;
$sidebar-nav-link-hover-color: $blueDark;
// $sidebar-nav-link-hover-bg: theme-color("primary") !default;
// $sidebar-nav-link-hover-bg: $gray-300;
$sidebar-nav-link-hover-bg:linear-gradient(-45deg,#3065b5,#33928f);
// $sidebar-nav-link-hover-icon-color: #fff !default;
$sidebar-nav-link-hover-icon-color: $blueDark;
// $sidebar-nav-link-hover-borders: 0 !default;
$sidebar-nav-link-hover-borders-left: 5px solid $blueDark;

// $sidebar-nav-link-active-color: $red;
$sidebar-nav-link-active-color: $white;
// $sidebar-nav-link-active-color: #fff !default;
// $sidebar-nav-link-active-bg: lighten($sidebar-bg, 5%) !default;
// $sidebar-nav-link-active-bg: $gray-300;
$sidebar-nav-link-active-bg: $blueDark;
// $sidebar-nav-link-active-icon-color: theme-color("primary") !default;
// $sidebar-nav-link-active-icon-color: theme-color("primary") !default;
// $sidebar-nav-link-active-icon-color: $red;
$sidebar-nav-link-active-icon-color: $white;
// $sidebar-nav-link-active-borders: 0 !default;
// $sidebar-nav-link-active-borders: 5px solid red;
$sidebar-nav-link-active-font-Weight: bold;
.sidebar .nav-link:hover {
  background-color: $gray-300;
  color: #031475;
  border-right: 2px solid #031475;
}
.sidebar .nav-link.active {
  border-left: 4px solid #031475;
}

$sidebar-nav-link-disabled-color: darken(#fff, 30%) !default;
$sidebar-nav-link-disabled-bg: $sidebar-bg !default;
$sidebar-nav-link-disabled-icon-color: $sidebar-nav-link-icon-color !default;
$sidebar-nav-link-disabled-borders: 0 !default;

// $sidebar-nav-dropdown-color: #fff !default;
// $sidebar-nav-dropdown-color: $gray-900;
$sidebar-nav-dropdown-color: $gray-900;

.sidebar .nav-dropdown.open {
  background-color: $gray-300;
  // border-left: 2px solid red;
}
.sidebar .nav-dropdown.open .nav-link:hover {
  background-color: $gray-400;
  color: #031475;
  border-right: 2px solid #031475;
}
// .sidebar .nav-dropdown.open .nav-link:active {
//   color: red;
//   border-right: 2px solid red;
// }

.sidebar .nav-dropdown .nav-link.active {
  color: $white;
  border-left: 4px solid #031475;
}
.sidebar li a .nav-link .nav-dropdown-toggle:first-child {
  border-bottom: 3px solid #031475;
}

// dropdown child background color
// .sidebar .nav-dropdown-items {
//   background-color: $gray-400;
//   border-top: 2px solid red;
// }

// $sidebar-nav-dropdown-bg: rgba(0, 0, 0, 0.2) !default;
// $sidebar-nav-dropdown-bg: $gray-200;
$sidebar-nav-dropdown-bg: $gray-200;
$sidebar-nav-dropdown-borders: 0 !default;
// $sidebar-nav-dropdown-indicator-color: $gray-600 !default;
$sidebar-nav-dropdown-indicator-color: $gray-600 !default;
$sidebar-nav-dropdown-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$sidebar-nav-dropdown-indicator-hover-color: $sidebar-nav-link-hover-color;
$sidebar-nav-dropdown-indicator-hover: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

// Sidebar Footer

$sidebar-footer-height: auto !default;
// $sidebar-footer-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-footer-padding-y: 0.75rem !default;
$sidebar-footer-padding-x: 1rem !default;
$sidebar-footer-borders: 0 !default;
.sidebar .sidebar-minimizer {
  background-color: $gray-200;
  border-top: 2px solid $gray-400;
}
.sidebar .sidebar-minimizer::before {
  background-color: $gray-200;
  // border-top: -2px solid red;
}

// Sidebar Minimizer

$sidebar-minimizer-height: 50px !default;
$sidebar-minimizer-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-minimizer-borders: 0 !default;
// $sidebar-minimizer-indicator-color: $gray-600 !default;
$sidebar-minimizer-indicator-color: $gray-600 !default;
$sidebar-minimizer-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$sidebar-minimizer-hover-bg: rgba(0, 0, 0, 0.3) !default;
$sidebar-minimizer-hover-indicator-color: $sidebar-nav-link-hover-color !default;
$sidebar-minimizer-hover-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

// Breadcrumb
$breadcrumb-borders: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color
  )
) !default;

// Aside

$aside-menu-width: 250px !default;
$aside-menu-color: $gray-800 !default;
$aside-menu-bg: #fff !default;
$aside-menu-borders: (
  left: (
    size: 1px,
    style: solid,
    color: $border-color
  )
) !default;

$aside-menu-nav-padding-y: 0.75rem !default;
$aside-menu-nav-padding-x: 1rem !default;

// Footer

$footer-height: 50px !default;
$footer-bg: $gray-100 !default;
$footer-color: $body-color !default;
$footer-borders: (
  top: (
    size: 1px,
    style: solid,
    color: $border-color
  )
) !default;

// Avatars

$avatar-width: 36px !default;
$avatar-status-width: 10px !default;

$avatar-lg-width: 72px !default;
$avatar-lg-status-width: 12px !default;

$avatar-sm-width: 24px !default;
$avatar-sm-status-width: 8px !default;

$avatar-xs-width: 20px !default;
$avatar-xs-status-width: 8px !default;

// Cards

$card-icon-bg: transparent !default;
$card-icon-color: $body-color !default;

// Switches

$switch-width: 40px !default;
$switch-height: 26px !default;
$switch-font-size: 10px !default;

$switch-lg-width: 48px !default;
$switch-lg-height: 30px !default;
$switch-lg-font-size: 12px !default;

$switch-sm-width: 32px !default;
$switch-sm-height: 22px !default;
$switch-sm-font-size: 8px !default;

$switch-label-width: 48px !default;
$switch-label-lg-width: 56px !default;
$switch-label-sm-width: 40px !default;

$switch-handle-margin: 2px !default;

// Cursor
$cursor-disabled: default !default;

// Breakpoints for mobile
$breakpoint-mobile-up: lg !default;
$breakpoint-mobile-down: md !default;



















// side bar info
// stylelint-disable selector-max-class, declaration-no-important, selector-max-compound-selectors, function-url-quotes, selector-max-combinators
.c-sidebar {
  position: relative;
  display: flex;
  // $sidebar-width is the width of the columns
  flex: 0 0 $sidebar-width;
  flex-direction: column;
  // put the nav on the left
  order: -1;
  width: $sidebar-width;
  padding: $sidebar-padding;
  box-shadow: none;

  &.c-sidebar-right {
    order: 99;
  }

  @include media-breakpoint-down(breakpoint-before($mobile-breakpoint)) {
    // Some of our components use this property to detect if the sidebar has mobile behavior.
    --is-mobile: true;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: $zindex-fixed + 1;

    @include ltr {
      &:not(.c-sidebar-right) {
        left: 0;
      }
      &.c-sidebar-right {
        right: 0;
      }
    }
    @include rtl {
      &:not(.c-sidebar-right) {
        right: 0;
      }
      &.c-sidebar-right {
        left: 0;
      }
    }
  }

  @include ltr {
    &:not(.c-sidebar-right) {
      margin-left: -$sidebar-width;
    }
    &.c-sidebar-right {
      margin-right: -$sidebar-width;
    }
  }

  @include rtl {
    &:not(.c-sidebar-right) {
      margin-right: -$sidebar-width;
    }
    &.c-sidebar-right {
      margin-left: -$sidebar-width;
    }
  }

  &[class*="bg-"] {
    border-color: rgba($black, 0.1);
  }

  @each $width, $value in $sidebar-widths {
    &.c-sidebar-#{$width} {
      flex: 0 0 $value;
      width: $value;

      @include ltr {
        &:not(.c-sidebar-right) {
          margin-left: -$value;
        }
        &.c-sidebar-right {
          margin-right: -$value;
        }
      }

      @include rtl {
        &:not(.c-sidebar-right) {
          margin-right: -$value;
        }
        &.c-sidebar-right {
          margin-left: -$value;
        }
      }
    }
  }

  &.c-sidebar-fixed {
    @include media-breakpoint-up($mobile-breakpoint) {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: $zindex-fixed;

      @include ltr {
        &:not(.c-sidebar-right) {
          left: 0;
        }

        &.c-sidebar-right {
          right: 0;
        }
      }

      @include rtl {
        &:not(.c-sidebar-right) {
          right: 0;
        }

        &.c-sidebar-right {
          left: 0;
        }
      }
    }
  }

  &.c-sidebar-overlaid {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: $zindex-fixed + 2;

    @include ltr {
      &:not(.c-sidebar-right) {
        left: 0;
      }

      &.c-sidebar-right {
        right: 0;
      }
    }

    @include rtl {
      &:not(.c-sidebar-right) {
        right: 0;
      }

      &.c-sidebar-right {
        left: 0;
      }
    }
  }
}

//
// Sidebar components
//

.c-sidebar-close {
  position: absolute;
  width: $sidebar-brand-height;
  height: $sidebar-brand-height;
  background: transparent;
  border: 0;

  @include hover() {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  @include ltr {
    right: 0;
  }

  @include rtl {
    left: 0;
  }
}

.c-sidebar-brand {
  display: flex;
  flex: 0 0 $sidebar-brand-height;
  align-items: center;
  justify-content: center;

  .c-sidebar-brand-minimized {
    display: none;
  }
}

.c-sidebar-header {
  flex: 0 0 $sidebar-header-height;
  padding: $sidebar-header-padding-y $sidebar-header-padding-x;
  text-align: center;
  transition: $layout-transition-speed;
}

// Sidebar navigation

.c-sidebar-nav {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;

  &.ps {
    // Firefox
    overflow: -moz-scrollbars-none;
    // IE 10+
    -ms-overflow-style: none;
    // Chrome, Edge, Safari, Opera
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}

.c-sidebar-nav-title {
  padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
  margin-top: $sidebar-nav-title-margin-top;
  font-size: 80%;
  font-weight: 700;
  text-transform: uppercase;
  transition: $layout-transition-speed;
}

.c-sidebar-nav-divider {
  height: 10px;
  transition: height $layout-transition-speed;
}

.c-sidebar-nav-item {
  width: inherit;
}

.c-sidebar-nav-link {
  display: flex;
  flex: 1;
  align-items: center;
  padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
  text-decoration: none;
  white-space: nowrap;
  transition: $layout-transition-speed,
    color $layout-transition-speed;
  @if $enable-sidebar-nav-rounded {
    border-radius: $border-radius;
  }

  .badge {
    @include ltr {
      margin-left: auto;
    }
    @include rtl {
      margin-right: auto;
    }
  }

  &.c-disabled {
    cursor: $cursor-disabled;
  }
  @media (hover: hover), (-ms-high-contrast: none) {
    &:hover {
      text-decoration: none;
    }
  }
}

.c-sidebar-nav-icon {
  flex: 0 0 $sidebar-nav-icon-width;
  height: ($font-size-base * 1.25);
  font-size: ($font-size-base * 1.25);
  text-align: center;
  transition: $layout-transition-speed;
  fill: currentColor;

  &:first-child {
    @include ltr {
      margin-left: -$sidebar-nav-link-padding-x;
    }
    @include rtl {
      margin-right: -$sidebar-nav-link-padding-x;
    }
  }
}

.c-sidebar-nav-dropdown {
  position: relative;
  transition:$layout-transition-speed ease-in-out;

  &.c-show {
    @if $enable-sidebar-nav-rounded {
      border-radius: $border-radius;
    }
    > .c-sidebar-nav-dropdown-items {
      max-height: 1500px;
      
    }

    > .c-sidebar-nav-dropdown-toggle::after {
      @include ltr {
        transform: rotate(-90deg);
      }
      @include rtl {
        transform: rotate(270deg);
      }
    }

    + .c-sidebar-nav-dropdown.c-show {
      margin-top: 1px;
      
    }
  }
}

.c-sidebar-nav-dropdown-toggle {
  @extend .c-sidebar-nav-link;
  cursor: pointer;

  &::after {
    display: block;
    flex: 0 8px;
    height: 8px;
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    transition: transform $layout-transition-speed;

    @include ltr {
      margin-left: auto;
    }
    @include rtl {
      margin-right: auto;
      transform: rotate(180deg);
    }
  }

  .badge {
    @include ltr {
      margin-right: $sidebar-nav-link-padding-x;
    }
    @include rtl {
      margin-left: $sidebar-nav-link-padding-x;
    }
  }
}

.c-sidebar-nav-dropdown-items {
  max-height: 0;
  // padding: 0;
  padding-left: 5%;
  overflow-y: hidden;
  list-style: none;
  transition: max-height $layout-transition-speed ease-in-out;
 

  .c-sidebar-nav-link {
    @include ltr {
      padding-left: $sidebar-nav-icon-width;
    }
    @include rtl {
      padding-right: $sidebar-nav-icon-width;
    }

    .c-sidebar-nav-icon {
      @include ltr {
        margin-left: -$sidebar-nav-icon-width;
      }
      @include rtl {
        margin-right: -$sidebar-nav-icon-width;
      }
    }
  }
}

.c-sidebar-nav[data-indentation="true"] {
  $wrapper-class-name: ".c-sidebar-nav-dropdown-items";
  @for $i from 1 through $sidebar-nav-indentation-levels {
    #{$wrapper-class-name} {
      .c-sidebar-nav-link {
        @include ltr {
          padding-left: $sidebar-nav-icon-width +
            ($i * $sidebar-nav-indentation-size);
        }
        @include rtl {
          padding-right: $sidebar-nav-icon-width +
            ($i * $sidebar-nav-indentation-size);
        }
        .c-sidebar-nav-icon {
          @include ltr {
            margin-left: -$sidebar-nav-icon-width;
          }
          @include rtl {
            margin-right: -$sidebar-nav-icon-width;
          }
        }
      }
    }
    $wrapper-class-name: selector-nest(
      $wrapper-class-name,
      $wrapper-class-name
    );
  }
}

.c-sidebar-nav-label {
  display: flex;
  padding: ($sidebar-nav-link-padding-y / 4) $sidebar-nav-link-padding-x;
  transition: $layout-transition-speed;

  &:hover {
    text-decoration: none;
  }

  .c-sidebar-nav-icon {
    margin-top: 1px;
  }
}

.c-sidebar-footer {
  flex: 0 0 $sidebar-footer-height;
  padding: $sidebar-footer-padding-y $sidebar-footer-padding-x;
  transition: $layout-transition-speed;
}

.c-sidebar-minimizer {
  display: flex;
  flex: 0 0 $sidebar-minimizer-height;
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  border: 0;

  @include media-breakpoint-down(breakpoint-before($mobile-breakpoint)) {
    display: none;
  }

  &::before {
    display: block;
    width: $sidebar-minimizer-height;
    height: $sidebar-minimizer-height;
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: $sidebar-minimizer-height / 4;
    transition: $layout-transition-speed;

    @include rtl {
      transform: rotate(180deg);
    }
  }

  &:focus,
  &.c-focus {
    outline: 0;
  }

  .c-sidebar-right & {
    justify-content: flex-start;
    &::before {
      @include ltr {
        transform: rotate(-180deg);
      }
      @include rtl {
        transform: rotate(0deg);
      }
    }
  }
}

// Modal background
.c-sidebar-backdrop {
  @include media-breakpoint-down(breakpoint-before($mobile-breakpoint)) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-fixed;
    width: 100vw;
    height: 100vh;
    background-color: $sidebar-backdrop-bg;
    transition: $layout-transition-speed;

    // Fade for backdrop
    &.c-fade {
      opacity: 0;
    }
    &.c-show {
      opacity: $sidebar-backdrop-opacity;
    }
  }
}

// Minimized Sidebar

.c-sidebar-minimized {
  @include media-breakpoint-up($mobile-breakpoint) {
    z-index: $zindex-fixed + 1;
    flex: 0 0 $sidebar-minimized-width;

    &.c-sidebar-fixed {
      z-index: $zindex-fixed + 1;
      width: $sidebar-minimized-width;
    }

    &:not(.c-sidebar-right) {
      @include ltr {
        margin-left: -$sidebar-minimized-width;
      }
      @include rtl {
        margin-right: -$sidebar-minimized-width;
      }
    }
    &.c-sidebar-right {
      @include ltr {
        margin-right: -$sidebar-minimized-width;
      }
      @include ltr {
        margin-left: -$sidebar-minimized-width;
      }
    }

    .c-sidebar-brand-full {
      display: none;
    }

    .c-sidebar-brand-minimized {
      display: block;
    }

    .c-sidebar-nav {
      padding-bottom: $sidebar-minimizer-height;
      overflow: visible;
    }

    .c-d-minimized-none,
    .c-sidebar-nav-divider,
    .c-sidebar-nav-label,
    .c-sidebar-nav-title,
    .c-sidebar-footer,
    .c-sidebar-form,
    .c-sidebar-header {
      height: 0;
      padding: 0;
      margin: 0;
      visibility: hidden;
      opacity: 0;
    }

    // Temporary fix for this issue: https://github.com/coreui/coreui-free-bootstrap-admin-template/issues/404
    // TODO: find better solution
    .c-sidebar-minimizer {
      position: fixed;
      bottom: 0;
      width: inherit;

      &::before {
        @include ltr {
          transform: rotate(-180deg);
        }
        @include rtl {
          transform: rotate(0deg);
        }
      }
    }

    &.c-sidebar-right .c-sidebar-minimizer::before {
      @include ltr {
        transform: rotate(0deg);
      }
      @include rtl {
        transform: rotate(180deg);
      }
    }

    &.c-sidebar-right .c-sidebar-nav {
      > .c-sidebar-nav-item:hover,
      > .c-sidebar-nav-dropdown:hover {
        @include ltr {
          margin-left: -$sidebar-width;
        }
        @include rtl {
          margin-right: -$sidebar-width;
        }
      }
    }

    .c-sidebar-nav-link,
    .c-sidebar-nav-dropdown-toggle {
      overflow: hidden;
      white-space: nowrap;
      border-left: 0;

      &:hover {
        width: $sidebar-width + $sidebar-minimized-width;
      }
    }

    .c-sidebar-nav-dropdown-toggle::after {
      display: none;
    }

    .c-sidebar-nav-dropdown-items {
      .c-sidebar-nav-link {
        width: $sidebar-width;
      }
    }

    // stylelint-disable-next-line no-duplicate-selectors
    .c-sidebar-nav > .c-sidebar-nav-dropdown {
      position: relative;

      > .c-sidebar-nav-dropdown-items {
        display: none;

        .c-sidebar-nav-dropdown:not(.c-show) > .c-sidebar-nav-dropdown-items {
          display: none;
        }
      }
      .c-sidebar-nav-dropdown-items {
        max-height: 1500px;
      }

      // stylelint-disable-next-line no-duplicate-selectors
      &:hover {
        width: $sidebar-width + $sidebar-minimized-width;
        overflow: visible;

        > .c-sidebar-nav-dropdown-items {
          position: absolute;
          display: inline;

          @include ltr {
            left: $sidebar-minimized-width;
          }
          @include rtl {
            right: $sidebar-minimized-width;
          }
        }
      }
    }

    &.c-sidebar-right
      > .c-sidebar-nav-dropdown:hover
      > .c-sidebar-nav-dropdown-items {
      @include ltr {
        left: 0;
      }
      @include rtl {
        right: 0;
      }
    }
  }
}

// Responsive behavior

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .c-sidebar.c-sidebar#{$infix}-show,
    .c-sidebar.c-sidebar-show {
      &:not(.c-sidebar-right) {
        @include ltr {
          margin-left: 0;
        }
        @include rtl {
          margin-right: 0;
        }
        @include media-breakpoint-up($mobile-breakpoint) {
          &.c-sidebar-fixed {
            ~ .c-wrapper {
              @include ltr {
                margin-left: $sidebar-width;
              }
              @include rtl {
                margin-right: $sidebar-width;
              }
            }
            @each $width, $value in $sidebar-widths {
              &.c-sidebar-#{$width} {
                ~ .c-wrapper {
                  @include ltr {
                    margin-left: $value;
                  }
                  @include rtl {
                    margin-right: $value;
                  }
                }
              }
            }
            &.c-sidebar-minimized {
              ~ .c-wrapper {
                @include ltr {
                  margin-left: $sidebar-minimized-width;
                }
                @include rtl {
                  margin-right: $sidebar-minimized-width;
                }
              }
            }
          }
        }
      }
      &.c-sidebar-right {
        @include ltr {
          margin-right: 0;
        }
        @include rtl {
          margin-left: 0;
        }
        @include media-breakpoint-up($mobile-breakpoint) {
          &.c-sidebar-fixed {
            ~ .c-wrapper {
              @include ltr {
                margin-right: $sidebar-width;
              }
              @include rtl {
                margin-left: $sidebar-width;
              }
            }
            @each $width, $value in $sidebar-widths {
              &.c-sidebar-#{$width} {
                ~ .c-wrapper {
                  @include ltr {
                    margin-right: $value;
                  }
                  @include rtl {
                    margin-left: $value;
                  }
                }
              }
            }
            &.c-sidebar-minimized {
              ~ .c-wrapper {
                @include ltr {
                  margin-right: $sidebar-minimized-width;
                }
                @include rtl {
                  margin-left: $sidebar-minimized-width;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include themes($sidebar-theme-map, $create: append, $parent: ".c-sidebar") {
  color: themes-get-value("sidebar-color");
  background: themes-get-value("sidebar-bg");

  @include borders(themes-get-value("sidebar-borders"));

  @include ltr {
    &.c-sidebar-right {
      @include borders(themes-get-value("sidebar-borders"), true);
    }
  }

  @include rtl {
    @include borders(themes-get-value("sidebar-borders"), true);
    &.c-sidebar-right {
      border: 0;
      @include borders(themes-get-value("sidebar-borders"));
    }
  }

  .c-sidebar-close {
    color: themes-get-value("sidebar-color");
  }

  .c-sidebar-brand {
    color: themes-get-value("sidebar-brand-color");
    background: themes-get-value("sidebar-brand-bg");
  }

  .c-sidebar-header {
    background: themes-get-value("sidebar-header-bg");
  }

  .c-sidebar-form .c-form-control {
    color: themes-get-value("sidebar-form-color");
    background: themes-get-value("sidebar-form-bg");
    border: themes-get-value("sidebar-form-border");

    &::placeholder {
      color: themes-get-value("sidebar-form-placeholder-color");
    }
  }

  .c-sidebar-nav-title {
    color: themes-get-value("sidebar-nav-title-color");
  }

  .c-sidebar-nav-link {
    color: themes-get-value("sidebar-nav-link-color");
    background: themes-get-value("sidebar-nav-link-bg");
    @include borders(themes-get-value("sidebar-nav-link-borders"));

    .c-sidebar-nav-icon {
      color: themes-get-value("sidebar-nav-link-icon-color");
    }

    &.c-active {
      // color: themes-get-value("sidebar-nav-link-active-color");
      // background: themes-get-value("sidebar-nav-link-active-bg");
      color: #fff;
      // background: $blueDark;
      background:linear-gradient(-45deg,#3065b5,#33928f);
      animation: active 0.2s linear;
      @include borders(themes-get-value("sidebar-nav-link-active-borders"));
      

      .c-sidebar-nav-icon {
        // color: themes-get-value("sidebar-nav-link-active-icon-color");
        color: #fff;
      }
      @keyframes active {
        0%{
            transform: scaleX(0)
        }
        50%{
            transform: scaleX(0.5)
        }
        100%{
            transform: scaleX(1)
        }
    }
  }
    

    &:focus {
      outline: none;
    }
    @media (hover: hover), (-ms-high-contrast: none) {
      &:hover {
        // color: themes-get-value("sidebar-nav-link-hover-color");
        color: #fff;
        // background: themes-get-value("sidebar-nav-link-hover-bg");
        // background: $sidebar-nav-link-hover-bg;

        // @include borders(themes-get-value("sidebar-nav-link-hover-borders"));
        // border-spacing: 0.5vh;
        // border-bottom:#fff;
        // border-style:ridge;
        background-image: $sidebar-nav-link-hover-bg;
        box-shadow: 0 10px 20px lightgray;

        .c-sidebar-nav-icon {
          color: themes-get-value("sidebar-nav-link-hover-icon-color");
        }

        &.c-sidebar-nav-dropdown-toggle::after {
          background-image: themes-get-value(
            "sidebar-nav-dropdown-indicator-hover"
          );
        }
      }
    }

    &.c-disabled {
      // color: themes-get-value("sidebar-nav-link-disabled-color");
      color:linear-gradient(-45deg,#3065b5,#33928f);
      background: transparent;
      @include borders(themes-get-value("sidebar-nav-link-disabled-borders"));

      .c-sidebar-nav-icon {
        color: themes-get-value("sidebar-nav-link-disabled-icon-color");
      }

      &:hover {
        color: themes-get-value("sidebar-nav-link-disabled-color");
        @include borders(themes-get-value("sidebar-nav-link-hover-borders"));

        .c-sidebar-nav-icon {
          color: themes-get-value("sidebar-nav-link-disabled-icon-color");
        }

        &.c-sidebar-nav-dropdown-toggle::after {
          background-image: themes-get-value(
            "sidebar-nav-dropdown-indicator-hover"
          );
        }
      }
    }
  }
  .c-sidebar-nav-dropdown-toggle {
    position: relative;
    border-bottom: grey;
    border-bottom-style: solid;
    color:linear-gradient(-45deg,#3065b5,#33928f);

    &::after {
      background-image: themes-get-value("sidebar-nav-dropdown-indicator");
      // color: themes-get-value("sidebar-nav-dropdown-color");
      
    }
  }

  .c-sidebar-nav-dropdown.c-show {
    background: themes-get-value("sidebar-nav-dropdown-bg");
    
    
    @include borders(themes-get-value("sidebar-nav-dropdown-borders"));

    .c-sidebar-nav-link {
         color: themes-get-value("sidebar-nav-dropdown-color");
        //  color: #fff;

        &.c-active {
            color: #fff;
        }
        &:hover {
          color: #fff;
          .c-sidebar-nav-icon {
            // color: themes-get-value("sidebar-nav-link-disabled-icon-color");
            color: #fff;
          }
        }
      &.c-disabled {
        // color: themes-get-value("sidebar-nav-link-disabled-color");
        color:$sidebar-nav-dropdown-color;
        background: transparent;

        &:hover {
          // color: themes-get-value("sidebar-nav-link-disabled-color");
          color: #fff;
          .c-sidebar-nav-icon {
            // color: themes-get-value("sidebar-nav-link-disabled-icon-color");
            color: #fff;
          }
        }
      }
    }
  }

  .c-sidebar-nav-label {
    color: themes-get-value("sidebar-nav-title-color");
  

    &:hover {
      color: themes-get-value("sidebar-color");
    }

    .c-sidebar-nav-icon {
      color: themes-get-value("sidebar-nav-link-icon-color");
    }
  }

  @if (lightness(themes-get-value("sidebar-bg")) < 40) {
    .c-progress {
      background-color: lighten(themes-get-value("sidebar-bg"), 15%) !important;
    }
  }

  .c-sidebar-footer {
    background: themes-get-value("sidebar-footer-bg");
    @include borders(themes-get-value("sidebar-footer-borders"));
  }

  .c-sidebar-minimizer {
    background-color: themes-get-value("sidebar-minimizer-bg");
    @include borders(themes-get-value("sidebar-minimizer-borders"));

    &::before {
      background-image: themes-get-value("sidebar-minimizer-indicator");
    }

    &:focus,
    &.c-focus {
      outline: 0;
    }

    &:hover {
      background-color: themes-get-value("sidebar-minimizer-hover-bg");
      &::before {
        background-image: themes-get-value("sidebar-minimizer-hover-indicator");
      }
    }
  }
  &.c-sidebar-minimized {
    .c-sidebar-nav-item {
      &:hover {
        > .c-sidebar-nav-link {
          background: themes-get-value("sidebar-nav-link-hover-bg");

          .c-sidebar-nav-icon {
            color: themes-get-value("sidebar-nav-link-hover-color");
          }

          &.c-disabled {
            background: themes-get-value("sidebar-nav-link-disabled-bg");

            .c-sidebar-nav-icon {
              color: themes-get-value("sidebar-nav-link-disabled-icon-color");
            }
          }
        }
      }
    }

    .c-sidebar-nav {
      > .c-sidebar-nav-dropdown {
        > .c-sidebar-nav-dropdown-items {
          background: themes-get-value("sidebar-bg");
        }

        &:hover {
          background: themes-get-value("sidebar-nav-link-hover-bg");
        }
      }
    }
  }
}

// stylelint-disable-next-line no-duplicate-selectors
.c-sidebar {
  @include theme-variant() {
    .c-sidebar-nav-link {
      &.c-sidebar-nav-link-#{$color} {
        background: $value;
        .c-sidebar-nav-icon {
          color: rgba(255, 255, 255, 0.7);
        }
        &:hover {
          background: darken($value, 5%);
          .c-sidebar-nav-icon {
            color: $white;
          }
        }
      }
    }
  }
}
