// If you want to override variables do it here
// @import "variables";
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here

@import "custom";

// ie fixes
@import "ie-fix";

// Body Sections css **********************************

// body {
//   margin: 0;
//   font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
//     sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
//     "Noto Color Emoji";
//   // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
//   // "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
//   // "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//   // font-size: 0.875rem;
//   font-size: 1.4rem;
//   // font-weight: 500;
//   line-height: 1.5;
//   color: #23282c;
//   text-align: left;
// }

.borderTopDiv{
  border-top:2px solid $blueDark;
}

.secondaryColor{
  color: $orange;
}
// for user profile
.profile-list {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: #fff;
  font-size: 21px;
  position: relative;
  top: 2px;
  border-radius: 100%;
}

// for family member profile icon

.family-member-list {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: #fff;
  font-size: 12px;
  position: relative;
  top: 2px;
  border-radius: 100%;
}

.titleNameLink {
  font-weight: 600;
  color: black;
  min-width: 200px;
}

#search-field,
#page-menu {
  // display: block;
  display: inline-block;
  // width: 100%;
  width: auto;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  margin-left: 5px;
  margin-bottom: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  // color: #5c6873;
  color: #5c6873;
  // background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input[type="search"] {
  box-sizing: border-box;
}

// pagination

html:not([dir="rtl"]) .pagination {
  // padding-left: 0;
}
.col-xs-8 {
  width: 66.66666667%;
  float: right;
}
.pagination {
  display: flex;
  list-style: none;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
  // margin: 0;
}
// .pull-right {
//   float: right !important;
// }
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 5% 1% 0 0;
  border-radius: 4px;
}

ul {
  display: block;
  list-style-type: disc;
  // margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.pagination > li {
  display: inline;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

a {
  //color: #031475;
  color: $blueDark;
  text-decoration: none;
  background-color: transparent;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  //background-color: #337ab7;
  background-color: $blueDark;
  // border-color: #337ab7;
  border-color: $blueDark;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-angle-double-left:before {
  content: "\f100";
}

// Domastic Dashboard css

// ***********************************************

.titleName {
  color: $blueDark;
}

.titleNameSecondary {
  color: $orange;
}
.btn-secondaryBtn {
  background-color:$orange;
  
}
.titleNameRprt {
  color: $blueDark;
}
.titleNameRprt:hover {
  color: $info;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imageSize {
  // width: 100%;
  height: 30px;
}

.BgColorCardsgray {
  background: linear-gradient(
    138deg,
    rgba(7, 43, 87, 1) 0%,
    rgba(74, 30, 73, 1) 74%,
    rgba(152, 74, 88, 1) 100%
  );
  color: white;
  border-radius: 10px;
}

.PopupBgCOlor {
  background: linear-gradient(
    138deg,
    rgba(7, 43, 87, 1) 0%,
    rgba(74, 30, 73, 1) 74%,
    rgba(152, 74, 88, 1) 100%
  );
  color: white;
  border-radius: 0px;
}

.BgColorCards {
  // background: linear-gradient(
  //   132deg,
  //   rgba(2, 0, 36, 0.8) 0%,
  //   rgba(9, 9, 121, 0.8) 35%,
  //   rgba(0, 255, 209, 0.8) 100%
  // );
  //   background: linear-gradient(
  //     138deg,
  //     rgba(11, 54, 105, 1) 30%,
  //     rgb(110, 110, 110) 100%
  //   );
  color: $blueDark;
  background-color: #fafafa;
  border-left: 3px solid $blueDark;
  border-right: 3px solid $gray-200;
  border-top: 0;
  border-bottom: 0;
  border-radius: 10px;
  background: linear-gradient(
    138deg,
    rgba(7, 43, 87, 1) 0%,
    rgba(74, 30, 73, 1) 74%,
    rgba(152, 74, 88, 1) 100%
  );
}






// error message Color

.errorColor {
  color: red;
  font-size: 85%;
}

// Drag and Drop Css

.board {
  display: inline-flexbox;
  width: 100%;
  min-height: 100px;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  // background-color: rgb(233, 233, 233);
  // padding: 1%;
}
.card {
  // padding: 1%;
  display: inline-flexbox;
  width: 100%;
  cursor: pointer;
  // background-color: royalblue;
  // color: black;
}

// React Table css
.table {
  border-left: none;
  border-right: none;
  width: 100%;
  margin-top: 0.3%;
  margin-bottom: 3%;

  thead,
  th,
  tr,
  td {
    //background-color:white;
    border-left: none;
    border-right: none;
    border-color: rgb(226, 226, 226);
    
  }

  tr td {
    line-height: 1;
  }

  tr:hover {
    background-color: #f0f3f5;
  }
}

.table thead {
  // text-align: center;
  cursor: pointer;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f1f3f6;
  color: $blueDark;
  border-top: 2px solid $blueDark;
  // border-bottom: 2px solid $blueDark;
  // background-color: $blueDark;
  // color: $white;
  width: 100%;
  margin-top: 10%;
}
.table .empty-cell {
  font-style: italic;
  //color: darkgray;
  color: white;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

th:last-child,
td:last-child,
th:nth-last-child(1),
td:nth-last-child(1) {
  text-align: center;
  width: 175px;
}

.sort-icon:after {
  float: right;
  color: rgb(168, 167, 167);
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sort-icon.sort-ascending:after {
  content: "\f0de";
}
.sort-icon.sort-descending:after {
  content: "\f0dd";
}
.sort-icon.sort-none:after {
  content: "\f0dc";
}



// socail medial icons colors

.facebook {
  color: $facebook;
}

.linkedIn {
  color: $linkedin;
}
.twitter {
  color: $twitter;
}
.instagram {
  color: $instagram;
}
.skype {
  color: $skype;
}
.website {
  color: $reddit;
}
.youtube {
  color: $youtube;
}
.blog {
  color: $vine;
}
.WhatsApp {
  color: $foursquare;
}
.vimeo {
  color: $vimeo;
}
.Interested {
  color: $reddit;
}
.Print {
  color: $facebook;
}
.Associations {
  color: $twitter;
}

// loader custom
.customBg{
  .modal-content{
    background-color:transparent !important;
    border-color:transparent !important;
    .modal-body{
      display: flex;
      justify-content: center;
      align-items: center;
       height: inherit;
     }
    
   }
   
}


// upload image 

.change-avatar-upload {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
 .profile-img {
	margin-right: 15px;
  img {
    border-radius: 4px;
    height: 100px;
    width: 100px;
    object-fit: cover;
}
 }
 .change-photo-btn {
    margin: 0 0 10px;
    width: 200px;
}
}



.change-photo-btn {
  background-color: #3065b5;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 0 auto;
  padding: 10px 15px;
  position: relative;
  transition: .3s;
  text-align: center;
  width: 200px;
    .upload {
      // display:none;
      bottom: 0;
        cursor: pointer;
        filter: alpha(opacity=0);
      left: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
      width: 220px;
      }


}


.bg-primary-transparent {
  background-color: rgba(48,101,181, 0.5);
}

.bg-info-transparent {
  background-color: rgba(32,168,216, 0.4);
}

.bg-warning-transparent {
  background-color: rgba(255,193,7, 0.7);
}

.bg-secondary-transparent {
  background-color: rgba(200,206,211, 0.7);
}

.bg-success-transparent {
  background-color: rgba(77,189,116, 0.7);
}


.bg-orange {
  background-color:$orange;
}
.btn-orange {
  background-color:$orange;
}

.bg-orange-gradient {
  background:linear-gradient(-45deg,#e97a24,#ffcc99);
}

.bg-primary-gradient {
  background:linear-gradient(-45deg,#3065b5,#33928f);
}

// button actions 

.btn-actions {
  display: inline-block;
  font-weight: 400;
  // color: #181b1e;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  // background-color: transparent;
  border: 1px solid transparent;
  padding: 0.1rem 0.4rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-bootstrap-table table {
  table-layout: initial !important;
}

// notification data 
.doc-review.review-listing {
	margin: 0;
}
.review-listing.doc-review > ul > li {
	background-color: #fff;
	border: 1px solid #f0f0f0;
	padding: 20px;
}

.review-listing {
  border-bottom: 1px solid #f5f7fc;
  margin-top: 20px;
  padding-bottom: 30px;
}
.review-listing > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.review-listing > ul li + li {
margin-top: 20px;
border-top: 1px dashed #f0f0f0;
padding-top: 20px;
}
.review-listing > ul li .comment {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
  margin-bottom: 30px;
}
.review-listing > ul li .comment:last-child {
margin-bottom: 0;
}
.review-listing > ul li .comment .comment-body {
  margin-left: 16px;
}
.review-listing > ul li .comment .comment-body .meta-data {
  position: relative;
  margin-bottom: 10px;
}
.review-listing > ul li .comment .comment-body .meta-data span {
  display: block;
  font-size: 16px;
  color: #757575;
}
.review-listing > ul li .comment .comment-body .meta-data span.comment-author {
  font-weight: 600;
  color: #272b41;
  text-transform: capitalize;
}
.review-listing > ul li .comment .comment-body .meta-data span.comment-date {
  font-size: 14px;
}
.review-listing > ul li .comment .comment-body .meta-data .review-count {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
  position: absolute;
  top: 3px;
  right: 0;
  width: auto;
}
.review-listing > ul li .comment .comment-body .comment-content {
  color: #757575;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}
.review-listing > ul li .comment .comment-body .comment-reply .comment-btn {
  color: #e97a24;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
}